/* Mixins */
.role-topo-drawer-component .save-button {
  margin-left: 16px;
}
.role-topo-drawer-component .menu-item-wrapper {
  border: solid 1px #d9d9d9;
  margin-bottom: 1px;
}
.role-topo-drawer-component .menu-item-wrapper .menu-item-header {
  padding: 12px 16px;
  background-color: #fafafa;
  font-size: 16px;
  font-weight: bold;
}
.role-topo-drawer-component .menu-item-wrapper .menu-item-content {
  padding: 12px 16px;
}